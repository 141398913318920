/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { useEffect } from 'react';
import { usePrevious } from '../usePrevious';

/**
 * Calls the callback when the value changes.
 *
 * Passes the previous and current values to the callback
 */
export function useChangeEffect<T>(
  value: T,
  callback: (previous: T | undefined, current: T) => void,
) {
  const previous = usePrevious(value);
  useEffect(() => {
    if (value !== previous) {
      callback(previous, value);
    }
  }, [value, previous, callback]);
}
